
/* Mobile styles */
@media (max-width: 600px) {
    /* Adjust the header styles for mobile */
    .header {
      padding: 1rem; /* Add padding to the header */
    }
  
    /* Adjust the content styles for mobile */
    .content {
      padding: 1rem; /* Add padding to the content */
    }
  
    /* Adjust the footer styles for mobile */
    .footer {
      padding: 1rem; /* Add padding to the footer */
    }
  }
  